<script setup lang="ts">
import type { Reply } from '@/types/api.js'
import type { Place, Status } from '@/types/place'
import Swal from 'sweetalert2'
import Popper from 'vue3-popper'

interface NameStatus {
  name: string
  locked: boolean
}

const ID_MODULE_MARKETPLACE = 'marketplaces'

const route = useRoute()
const nuxtApp = useNuxtApp()
const globalStore = useGlobal()

const selectedCreateNameStatus = ref<Status>({ locked: false, name: '' })
const createNameStatus = reactive([
  { name: 'Habilitado', locked: false },
  { name: 'Demorado', locked: false },
  { name: 'Cerrado', locked: false },
])

const blocked = ref(false)

function createMenuItems(id: string) {
  const menu = [
    {
      icon: '/assets/img/Comercio.svg',
      label: 'Mi Comercio',
      path: `/places/${id}`,
      name: 'home',
      active: true,
      urlAccess: 'place',
    },
    {
      icon: '/assets/img/Configuracion.svg',
      label: 'Configuración',
      path: `/places/${id}/setup`,
      name: 'setup',
      active: true,
      urlAccess: 'setup',
    },
    {
      icon: '/assets/img/Ordenes.svg',
      label: 'Órdenes',
      path: `/places/${id}/orders`,
      name: 'orders',
      active: true,
      urlAccess: 'order',
    },
    {
      icon: '/assets/img/Marketplace.svg',
      label: 'Marketplaces',
      path: 'javascript:;',
      name: 'marketplaces',
      active: false,
      urlAccess: 'domiplace',
    },
    {
      icon: '/assets/img/POS.svg',
      label: 'POS',
      path: `/places/${id}/pos`,
      name: 'pos',
      active: false,
      urlAccess: 'selforder',
    },
    {
      icon: '/assets/img/Wholesale.svg',
      label: 'Wholesale',
      path: `/places/${id}/whole-sale`,
      name: 'whole-sale',
      active: true,
      urlAccess: 'wholesale',
    },
    {
      icon: '/assets/img/Envios.svg',
      label: 'Envíos',
      path: `/places/${id}/runner`,
      name: 'sent',
      active: false,
      urlAccess: 'runner',
    },
    {
      icon: '/assets/img/Inventario.svg',
      label: 'Inventario',
      path: `/places/${id}/invetory`,
      name: 'invetory',
      active: false,
      urlAccess: 'stock',
    },
    {
      icon: '/assets/img/MenuDigital.svg',
      label: 'Menú Digital',
      path: `/places/${id}/digital-menu`,
      name: 'digital-menu',
      active: false,
      urlAccess: 'digital',
    },
    {
      icon: '/assets/img/FacturaDigital.svg',
      label: 'Factura Digital',
      path: `/places/${id}/invoice`,
      name: 'digital-invoice',
      active: false,
      urlAccess: 'invoice',
    },
  ]

  menu.forEach((item) => {
    const module = globalStore.placeModulesRef.find(placeModule => placeModule.urlAccess === item.urlAccess)

    if (module && module.PlaceModules.active) {
      item.active = true
    }
  })
  return menu.sort((a, b) => Number(b.active) - Number(a.active))
}

async function getPlacesId(id: string) {
  try {
    const response = await nuxtApp.$urlEfici.post<Reply<Place>>(`${nuxtApp.$endpoint.places.getAll.url}${id}`)

    if (response.data.error)
      throw new Error(response.data.message)
    globalStore.place = response.data.data

    selectedCreateNameStatus.value.name = response.data.data.status.name
  }
  catch (e) {
    blocked.value = true
    console.error((e as Error).message)
    throw e
  }
}

async function handleStatusPlace(nameStatus: NameStatus) {
  if (nameStatus.name === 'Cerrado') {
    const resultQuestion = await Swal.fire({
      icon: 'warning',
      showCancelButton: true,
      showDenyButton: true,
      denyButtonText: 'Cerrar por hoy',
      confirmButtonText: 'Cerrar indefinidamente',
      cancelButtonText: 'Cancelar',

      title: '¿Quieres que esta acción sea permanente?',
      text: 'Recuerda que si es permanente debes cambiarla manualmente pero sino es permanente solo se cierra por el de hoy.',
      padding: '2em',
    })

    if (resultQuestion.isDismissed)
      return

    if (resultQuestion.isConfirmed) {
      nameStatus.locked = true
    }
  }

  selectedCreateNameStatus.value = nameStatus

  try {
    const response = await nuxtApp.$urlEfici.patch<Reply<unknown>>(`${nuxtApp.$endpoint.places.editStatus.url}${route.params.id}`, { status: { ...nameStatus } })

    if (response.data.error)
      throw new Error(response.data.message)
  }
  catch (e) {
    console.error((e as Error).message)
    throw e
  }
}

watch(() => globalStore.place, (state) => {
  if (state) {
    const nameStatuIndex = createNameStatus.findIndex(nameStatus => nameStatus.name === globalStore.place?.status.name)

    if (nameStatuIndex !== -1) {
      if (Object.prototype.hasOwnProperty.call(globalStore.place?.status, 'locked')) {
        selectedCreateNameStatus.value = globalStore.place?.status as Status
        createNameStatus[nameStatuIndex] = globalStore.place?.status as Status
      }
      else {
        createNameStatus[nameStatuIndex] = { ...(globalStore.place?.status as Status), locked: false }
        selectedCreateNameStatus.value = { ...(globalStore.place?.status as Status), locked: false }
      }
    }

    globalStore.getPlaceModules()
  }
})

onMounted(() => {
  getPlacesId(route.params.id as string)
})
</script>

<template>
  <NuxtLayout v-if="!blocked">
    <div class="mb-5 flex flex-col gap-5 items-center">
      <h4 class="text-lg ">
        {{ $t('adminPlace') }} <span class="font-bold">{{ globalStore.place && globalStore.place.name }}</span>
      </h4>
      <div class="flex items-center gap-8">
        <ClientOnly>
          <button
            v-for="nameStatus in createNameStatus"
            :key="nameStatus.name"
            class="btn btn-primary p-2"
            :class="{
              '!btn-success text-white':
                selectedCreateNameStatus.name === nameStatus.name,
            }"
            :disabled="selectedCreateNameStatus.name === nameStatus.name && nameStatus.locked"
            @click="() => handleStatusPlace(nameStatus)"
          >
            <span :class="{ 'mr-2': selectedCreateNameStatus.name === nameStatus.name && nameStatus.locked }">
              {{ nameStatus.name }}
            </span>
            <Icon
              v-if="selectedCreateNameStatus.name === nameStatus.name && nameStatus.locked"
              name="hugeicons:circle-lock-02"
            />
          </button>
          <template #fallback>
            <div
              v-for="nameStatus in createNameStatus"
              :key="nameStatus.name"
              class="h-6 animate-pulse bg-gray-500"
            />
          </template>
        </ClientOnly>
      </div>
    </div>
    <div class="flex w-full mb-4">
      <div class="flex gap-4 flex-nowrap max-w-full overflow-x-auto scrollbar">
        <div
          v-for="(item, index) in createMenuItems(route.params.id as string)"
          :key="index"
          class="w-28"
        >
          <ClientOnly>
            <Popper
              offset-distance="0"
              class="align-middle"
            >
              <NuxtLink
                class="flex flex-col w-28 dark:bg-[#181F32] items-center justify-center p-3 rounded-lg bg-gray-100"
                :class="{
                  'bg-gray-100 text-primary dark:bg-[#181F32] dark:text-primary': route.fullPath === item.path || route.fullPath.includes(item.name),
                  'bg-gray-200 opacity-50 cursor-not-allowed': !item.active,
                }"
                :to="item.active ? item.path : 'javascript:;'"
              >
                <NuxtImg
                  :src="item.icon"
                  class="mb-2 size-6"
                  :class="{ ' grayscale': !item.active }"
                  loading="lazy"
                />
                <span class="text-sm text-center truncate">{{ item.label }}</span>
              </NuxtLink>
              <template v-if="item.name === ID_MODULE_MARKETPLACE && item.active" #content="{ close }">
                <nav class="whitespace-nowrap flex flex-col gap-4 dropdown-content" @click="close()">
                  <NuxtLink class="dropdown-item" :to="`/places/${route.params.id}/marketplaces/domiplace`">
                    Domiplace
                  </NuxtLink>
                  <NuxtLink class="dropdown-item" to="javascript:;">
                    Modash
                  </NuxtLink>
                  <NuxtLink class="dropdown-item" to="javascript:;">
                    Buy loo
                  </NuxtLink>
                </nav>
              </template>
            </Popper>
          </ClientOnly>
        </div>
      </div>
    </div>
    <slot />
    <Due />
  </NuxtLayout>
  <NuxtLayout v-else>
    <Blocked />
  </NuxtLayout>
</template>
