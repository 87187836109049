<template>
  <div>
    <div
      class="relative flex min-h-screen items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16"
    >
      <div
        class="relative w-full max-w-[870px] rounded-md p-2 text-center"
      >
        <div class="rounded-md bg-white/60 p-4 backdrop-blur-lg dark:bg-black/50 sm:p-6">
          <div class="mx-auto mt-5 w-full max-w-[550px] md:mt-16">
            <div class="mb-12">
              <h4 class="text-xl font-extrabold uppercase !leading-snug text-danger md:text-4xl">
                No tienes permisos para ver esta página
              </h4>
            </div>

            <div class="mb-20 md:mb-32">
              <ul class="flex justify-center gap-3.5">
                <li>
                  <a
                    href="https://instagram.com/domiplace_"
                    class="inline-flex h-8 w-8 items-center justify-center rounded-full p-0 transition hover:scale-110"
                  >
                    <Icon name="hugeicons:instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/domiplace"
                    class="inline-flex h-8 w-8 items-center justify-center rounded-full p-0 transition hover:scale-110"
                  >
                    <Icon name="hugeicons:facebook-02" />
                  </a>
                </li>
              </ul>
            </div>
            <p class="dark:text-white">
              © {{ new Date().getFullYear() }}. Efici All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
